<script>
import axios from 'axios';
import Layout from "../layouts/horizontal";
import PageHeader from "@/components/page-header";
import {
   
} from "@/state/helpers";


export default {
  components: {
    Layout,
    PageHeader
  },
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      title: 'Knowledge Hub',
      breadcrumbs: [
      {
          text: "Knowledge Hub",
          to: { name: 'knowledgehub' }
        },
        {
          text: 'Article',
          active: true
        }
      ],
      html: ''
    };
  },
  methods: {
    async fetchArticles() {
      const response = await axios.get('https://c8hedge.com/api/articles/' + this.id);
      this.html = response.data
    }
  },
  created() {
    this.fetchArticles();
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div v-html="html"></div>
  </Layout>
</template>